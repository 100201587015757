export default function accrdion() {
  document.addEventListener("DOMContentLoaded", function () {
    $(function () {
      $('.m3pcom-help-list--inner').css("display", "none");
      $('.m3pcom-js-help-btn').on('click', function () {
        $(this).next().slideToggle(300);
      })
    });
  });
}
